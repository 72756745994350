.gallery-icon{
    font-size: 30px;
    padding-right: 10px;
    cursor: pointer;
}
.file-node-label{
    margin: 20px 40px;
    font-size: 20px;
    display: flex;

}
.folder-title{
    font-size: 22px;
    font-weight: 600;
}
.tree-buttons{
    display: flex;
}
.d_flex{
    display: flex;
}


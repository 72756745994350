/* ModalComponent.css */
.container{
    max-width: 700px;
    width: 100%;
    background: #fff;
    padding: 25px 30px;
    border-radius: 5px;
}

.container .title{
    font-size: 20px;
    font-weight: 500;
    position: relative;
}

.container .title::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 30px;
    background: #efdda8;
}

.container form .vendor-details{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 12px 0;
}
.description-container{
    width: 100%;
    height: 80%;
    background: #fff;
    padding: 25px 30px;
    border-radius: 5px;
}

.description-container .title{
    font-size: 20px;
    font-weight: 500;
    position: relative;
}

.description-container .title::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 30px;
    background: #efdda8;
}

.description-container form .vendor-details{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 12px 0;
}
.description-container form .create-order{
    display: flex;
    justify-content: space-between;
    margin: 20px 0 12px 0;
}

form .vendor-details .input-box{
    width: calc(100% / 2 - 20px);
    margin-bottom: 15px;
}
form .create-order .input-box{
    width: calc(100% / 2 - 20px);
    margin-bottom: 15px;
}
.input-box{
    font-weight: 600;
    margin-bottom: 5px;
    display: block;
}

.vendor-details .input-box .details{
    font-weight: 600;
    margin-bottom: 5px;
    display: block;
}
.create-order .input-box .details{
    font-weight: 600;
    margin-bottom: 5px;
    display: block;
}
.grade-container{
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.text-area{
    width: 285px;
    height: 120px;
    outline: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding-left: 15px;
    padding-top: 10px;
    font-size: 13px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
}
.text-area:focus{
    border-color: #efdda8;

}
.order-text-area{
    width: 550px;
    height: 80px;
    outline: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding-left: 15px;
    padding-top: 10px;
    font-size: 13px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
}
.order-text-area:focus{
    border-color: #efdda8;

}
.common-text-area{
    width: 285px;
    height: 40px;
    outline: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding-left: 15px;
    padding-top: 10px;
    font-size: 13px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
}
.common-text-area:focus{
    border-color: #efdda8;

}
.vendor-details .input-box input{
    height: 40px;
    width: 95%;
    outline: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding-left: 15px;
    font-size: 13px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
}
.vendor-details .input-box .custom-datepicker{
    height: 40px;
    width: 95%;
    outline: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding-left: 25px;
    font-size: 13px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
}

.vendor-details .input-box input:focus{
    border-color: #efdda8;
}
.create-order .input-box input{
    height: 40px;
    width: 95%;
    outline: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding-left: 15px;
    font-size: 13px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
}
.create-order .input-box .custom-datepicker{
    height: 40px;
    width: 95%;
    outline: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding-left: 25px;
    font-size: 13px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
}

.create-order .input-box input:focus{
    border-color: #efdda8;
}

.buttons{
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.buttons-second{
    display: flex;
    justify-content: space-around;
}

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.75) !important;
    zIndex: 1000;
}

.ReactModal__Content {
    width: fit-content;
    height: fit-content;
    borderRadius: 10px;
    margin: auto;
    zIndex: 1001;
}

.Modal{
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
}
/* Modal Overlay Styles */
.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.75) !important;
    z-index: 1000 !important;
}

/* Modal Content Styles */
.ReactModal__Content {
    width: fit-content;
    height: fit-content;
    border-radius: 10px;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    z-index: 1001 !important;
}

/* Modal Default Styles */
.Modal {
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    transition: all 0.3s ease;
}
/* For Small Screens (Mobile Phones) */
@media (max-width: 768px) {
    .ReactModal__Content {
        width: 90%; /* Set modal width to 90% for mobile */
        padding: 15px;
    }

    .Modal {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); /* Center the modal */
        width: 90%; /* Adjust width for mobile */
        max-height: 90%; /* Ensure modal doesn’t overflow the screen */
        overflow-y: auto;
    }
}

/* For Extra Small Screens (Mobile Phones - Portrait) */
@media (max-width: 480px) {
    .ReactModal__Content {
        width: 95%; /* Set modal width to 95% for extra small screens */
        padding: 10px;
    }

    .Modal {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); /* Center the modal */
        width: 95%; /* Adjust width for mobile */
        max-height: 95%; /* Ensure modal fits in small screens */
        overflow-y: auto;
    }
}

.description-modal{
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    width: 1400px;
    height: 800px ;
}
.print-modal{
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
}
.create-order-modal{
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    width: 1100px;
    height: 700px ;
}
.region-state-modal{
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    width: 730px;
    height: 500px;
}
.sales-report-modal{
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    width: 700px;
    height: 612px;
}
.medium-table-modal{
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    width: 1350px;
    height: 600px;
}

/* For Small Screens (Mobile Phones) */
@media (max-width: 768px) {
    .description-modal,
    .print-modal,
    .create-order-modal,
    .region-state-modal,
    .sales-report-modal,
    .medium-table-modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); /* Center the modal */
        width: 90%; /* Set modal width to 90% for small screens */
        max-height: 90%; /* Ensure modal doesn’t overflow the screen */
        overflow-y: auto; /* Enable scrolling for overflowing content */
        border-radius: 4px;
        inset: auto; /* Ignore large insets on smaller screens */
    }

    .description-modal {
        width: 90%;
        height: auto; /* Adjust height dynamically */
    }

    .print-modal {
        width: 90%;
        height: auto;
    }

    .create-order-modal {
        width: 90%;
        height: auto;
    }

    .region-state-modal {
        width: 85%;
        height: auto;
    }

    .sales-report-modal {
        width: 85%;
        height: auto;
    }

    .medium-table-modal {
        width: 95%;
        height: auto;
    }
}

/* For Extra Small Screens (Mobile Phones - Portrait) */
@media (max-width: 480px) {
    .description-modal,
    .print-modal,
    .create-order-modal,
    .region-state-modal,
    .sales-report-modal,
    .medium-table-modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 95%; /* Set modal width to 95% for extra small screens */
        max-height: 95%; /* Fit within smaller screen sizes */
        padding: 10px; /* Adjust padding for extra-small screens */
        overflow-y: auto;
    }

    .description-modal {
        width: 95%;
        height: auto;
    }

    .print-modal {
        width: 95%;
        height: auto;
    }

    .create-order-modal {
        width: 95%;
        height: auto;
    }

    .region-state-modal {
        width: 90%;
        height: auto;
    }

    .sales-report-modal {
        width: 90%;
        height: auto;
    }

    .medium-table-modal {
        width: 95%;
        height: auto;
    }
}


/* Form */

.error-message {
    color: #ff0000;
    font-size: 12px;
    margin-top: 5px;
}

.success-message{
    color: #088830;
    font-size: 16px;
    margin-top: 5px;
    align-items: center;
    text-align: center;
    font-weight: bold;
}

.failure-message{
    color: #ff0000;
    font-size: 16px;
    margin-top: 5px;
    align-items: center;
    text-align: center;
    font-weight: bold;
}

.select{
    height: 40px;
    width: 95%;
    outline: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding-left: 15px;
    font-size: 13px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
}

.select:focus{
    border-color: #efdda8;
}
.select option {
    background-color: #fff;
    color: #333;
}
.select option:hover {
    background-color: #efdda8;
    color: #333
}

.sales-entry-form {
    width: 100%;
    max-width: 600px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.step {
    margin-bottom: 20px;
}

.step-1 select {
    width: 100%;
    padding: 8px;
    margin-top: 10px;
}

.customer-info h2 {
    margin: 0;
}

.step-2 .input-group {
    margin-bottom: 15px;
}

.step-2 .input-group label {
    display: block;
    margin-bottom: 5px;
}

.step-2 .input-group input {
    width: 100%;
    padding: 8px;
}

.products-table {
    margin-top: 20px;
}

.product-row {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    height: 40px;
    width: 98%;
    outline: none;
    border-radius: 5px;
    font-size: 13px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
}
.product-row .form-input:focus{
    border-color: #efdda8;
}

.product-row input {
    flex: 1;
    padding: 8px;
}

.add-row{
    text-decoration: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 15px;
    color: #282c34;

}

.products-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.products-table th, .products-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
    font-size: 13px;
}

.products-table th {
    background-color: #f8f0d9;
}

.products-table td input {
    height: 40px;
    width: 95%;
    outline: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding-left: 15px;
    font-size: 13px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
}
.products-table td input:focus {
    border-color: #efdda8;
}
.subtotal-label {
    text-align: left;
    font-weight: bold;
}

.upload-input {
    margin: 30px 0px 50px 50px;
}
.content-upload-input {
    margin: 0px 0px 0px 0px;
    padding: 5px;
}
.upload-heading{
    margin: 40px 0px 40px 40px;
}
.description-text{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100px;
}
.image-table {
    max-width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    display: block;
    max-height: 300px; /* Adjust this height as needed */
    overflow-y: auto;
    overflow-x: auto;
}
.image-table th, .image-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.image-table th {
    background-color: #f8f0d9;
}

.uploaded-image {
    max-width: 100px;
    max-height: 100px;
}
.editor{
    margin: 20px;
}

.pipeline-tabs-container {
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 15px;
    background-color: #f9f9f9;
}

.pipeline-tab {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-bottom: 2px solid transparent;
    text-align: center;
}

.pipeline-tab:not(.pipeline-active):hover {
    background-color: #f0f0f0; /* Hover effect for inactive tabs */
}

.pipeline-tab.pipeline-active {
    font-weight: bold;
    background-color: #f0f0f0; /* Optional: Background color for active tab */
}

.pipeline-tab + .pipeline-tab {
    margin-left: 5px; /* Optional: Space between tabs */
}

/* Responsive styles for small screen sizes */
@media screen and (max-width: 768px) {
    .pipeline-tabs-container {
        flex-direction: column; /* Stack tabs vertically */
        margin-left: 5px;
        margin-right: 5px;
        font-size: 14px; /* Slightly smaller font size */
    }

    .pipeline-tab {
        padding: 8px 15px; /* Reduce padding for smaller screens */
        margin-bottom: 5px; /* Add space between stacked tabs */
    }

    .pipeline-tab + .pipeline-tab {
        margin-left: 0; /* Remove horizontal spacing */
    }
}

@media screen and (max-width: 480px) {
    .pipeline-tabs-container {
        margin-left: 2px;
        margin-right: 2px;
        font-size: 13px; /* Further reduce font size for very small screens */
    }

    .pipeline-tab {
        padding: 6px 10px; /* Reduce padding further for very small screens */
        font-size: 12px; /* Adjust font size for readability */
    }
}

/*breadcrumb*/

ul.breadcrumb {
    display: block;
    height: 50px;
    margin: auto;
    list-style-type: none;
    margin-top: -13px
}

ul.breadcrumb > li.crumb.first-crumb {
    z-index: 300;
}

ul.breadcrumb > li.crumb.middle-crumb {
    z-index: 200;
}

ul.breadcrumb > li.crumb.last-crumb {
    z-index: 100;
}

ul.breadcrumb > li.crumb {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    height: 0;
    float: left;
    color: black;
    font-size: 15px;
    margin-left: -14px;
    padding: 5px;
    padding-left: 20px;
    line-height: 40px;
    border-bottom: 15px solid #efdda8;
    border-right: 15px solid transparent;
    transition: .2s border-color linear;
}

ul.breadcrumb > li.crumb:hover,
ul.breadcrumb > li.crumb:hover:after {
    cursor: text;
    border-top-color: #efdda8;
    border-bottom-color: #efdda8;
    transition: .2s border-color linear;
}

li.crumb:after,
li.crumb:before {
    content: '';
    position: absolute;
    left: 0;
    transition: .2s border-color linear;
}

li.crumb:after {
    width: 106%;
    top: 25px;
    z-index: -1;
    border-top: 15px solid #efdda8;
    border-right: 15px solid transparent;
}

li.crumb:before {
    top: 100%;
    border-left: 15px solid #f7f7f7;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}


.grid-container {
    display: grid;
    grid-template-columns: 260px 1fr 1fr 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'sidebar header header header'
      'sidebar main main main';
    height: 100vh;
  }

  .icon {
    vertical-align: middle;
    line-height: 1px;
    font-size: 20px;
  }
.nav-icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 20px;
  margin: 10px;
  color: #fff;
}

.search-icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 20px;
  margin: 10px;
  color: #000;
}
  .icon_header {
    vertical-align: middle;
    line-height: 1px;
    font-size: 26px;
  }
  .icon, .icon_header {
    margin-right: 5px;
  }

  .close_icon {
    color: #d3d3d3;
    margin-left: 30px;
    margin-top: 10px;
    cursor: pointer;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 260px 1fr 1fr 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'sidebar header header header'
      'sidebar main main main';
    height: 100vh;
  }

  /* Header  */
  .header {
    height: 55px;
    grid-area: header;
    display: flex;
    align-items: center;
    padding: 0 30px 0 30px;
    box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
    background-color: #fff;
    color: #000;
    font-weight: bold;
    cursor: pointer;
  }

.sub-header {
  grid-area: header;
  display: flex;
  align-items: center;
  padding: 0 30px 0 30px;
  box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
  background-color: #efdda8;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  justify-content: space-between;
}
.sub-header_box {
  grid-area: header;
  display: flex;
  align-items: center;
  padding: 0 30px 0 30px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  justify-content: space-between;
}
.subheader-components{
  display: flex;
}

.action-components{
  display: flex;
  justify-content: center;
}
.action-small-components{
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.action-buttons{
  width: 50px;
}

.filter-button{
  background: #fff;
  color: #000;
  display: flex;
  justify-content: space-around;
  margin: 5px;
  border-radius: 20px;
  font-size: 12px;
  width: 100%;
  padding: 10px;
  border: none;
}

.filter-button:hover {
  background: #282c34;
  color: #f9f9f9;
  transition: 0.2s;
}

.common-button{
  background: #fff;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 5px;
  border-radius: 20px;
  font-size: 13px;
  width: 100%;
  padding: 10px;
  border: none;
}

.action-button{
  background: #fff;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  font-size: 13px;
  padding: 10px;
  border: none;
  cursor: pointer;
}
.action-button:hover {
  background: #282c34;
  color: #f9f9f9;
  transition: 0.2s;
}

.common-button:hover {
  background: #282c34;
  color: #f9f9f9;
  transition: 0.2s;
}


.header-icons{
  background: #ddd;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 5px;
  border-radius: 20px;
  font-size: 14px;
  width: 100%;
  padding: 10px;
  border: none;
}

.header-icons:hover {
  background: #efdda8;
  color: #000;
  transition: 0.2s;
}

.header-icons:active {
  box-shadow: 2px 2px 5px #fc894d;
}


.menu-icon {
    display: none;
  }

  .logo{
    width: 40px;

  }

  .header-logo{
    margin-right: 20px;
  }

  .logout-icon{
    position: absolute;
    float: right;
    right: 35px;
  }

.filter-icon{
  margin-top: 3px;
  margin-right: 5px;
}

.action-icon{
  align-items: center;
  justify-content: center;
  text-align: center;
}
  /* End Header  */

@media (max-width: 768px) {
  /* Hide desktop menu on small screens */
  .header-menu {
    display: none;
  }

  .header.menu-open .header-menu {
    display: block;
  }

  .subheader-components {
    text-align: left;
    padding: 10px;
  }

  .menu-icon {
    display: block;
    color: #000;
  }

  .logo {
    width: 30px;
  }

  .logout-icon {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

/* Mobile Menu Icon */
.menu-icon {
  display: none;
  cursor: pointer;
  font-size: 24px;
  margin-left: auto;
}
/* Mobile Menu Styles */
.header.menu-open .header-menu {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 200px;
  height: 100%;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  padding-top: 60px;
  z-index: 9999;
}

.header.menu-open .menu-icon {
  display: block;
}
/* Medium screens (768px to 1023px) */
@media (min-width: 769px) and (max-width: 1100px) {
  .header-menu {
    display: none;
  }

  .header.menu-open .header-menu {
    display: block;
  }

  .subheader-components {
    text-align: left;
    padding: 10px;
  }

  .menu-icon {
    display: block;
    color: #000;
  }

  .logo {
    width: 40px;
  }

  .logout-icon {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

/* Large screens (1024px to 1279px) */
@media (min-width: 1101px) and (max-width: 3200px) {
  .header-menu {
    display: flex;
    justify-content: space-around;
  }

  .menu-icon {
    display: none;
  }
}


.subheader-box{
  display: flex;
  align-items: center;
}

.table-row{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}
.product-table-row{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
}
.product-table-small-row{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}
.action{
  margin-top: -12px;
}

/* Table */
.table-container{
  padding: 20px;
  font-size: 12px;
}
.table-default-container{
  padding: 0px;
  font-size: 12px;
}

.table-header{
  background-color: burlywood;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #ddd;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px;
}
.table-header-content{
  background-color: burlywood;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #ddd;
}
.header-box{
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  height: 50px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.table {
  display: grid;
  width: 100%;
  border-collapse: collapse;
}
.website-table {
  display: grid;
  width: 100%;
  border-collapse: collapse;
}

.row-header {
  background-color: #f8f0d9;
  font-weight: bold;
  margin: 0px 10px 0px 10px;
}
.user-row-header {
  background-color: #f8f0d9;
  font-weight: bold;
  margin: 0px 10px 0px 10px;
}
.website-row-header {
  background-color: #f8f0d9;
  font-weight: bold;
}
.curriculum-row-header {
  background-color: #f8f0d9;
  font-weight: bold;
  margin: 20px 10px 0px 10px;
}

.row {
  display: contents;
}

.inventory-cell {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ddd;
  width: calc(100% / 11);
  box-sizing: border-box;
}

.vendor-cell {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ddd;
  width: calc(100% / 6);
  box-sizing: border-box;
  height: 50px;
}

.product-quantity-cell {
  display: inline-block;
  padding: 20px 20px 40px 10px;
  border: 1px solid #ddd;
  width: calc(100% / 11);
  box-sizing: border-box;
  height: 80px;
  width: 120px;
}
.product-heading-cell {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ddd;
  width: calc(100% / 11);
  box-sizing: border-box;
  height: 50px;
}
.product-heading-quantity-cell {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ddd;
  width: calc(100% / 11);
  box-sizing: border-box;
  height: 50px;
  width: 82px;
}
.product-heading-action-cell {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ddd;
  width: calc(100% / 11);
  box-sizing: border-box;
  height: 50px;
  width: 235.7px;
}
.product-heading-small-action-cell {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ddd;
  width: calc(100% / 11);
  box-sizing: border-box;
  height: 50px;
  width: 299.7px;
}
.two-column-cell {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ddd;
  width: calc(100% / 2);
  box-sizing: border-box;
  height: 50px;
}
.three-column-cell {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ddd;
  width: calc(100% / 3);
  box-sizing: border-box;
  height: 51px;
}
.four-column-cell {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ddd;
  width: calc(100% / 4);
  box-sizing: border-box;
  height: 50px;
}
.five-column-cell {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ddd;
  width: calc(100% / 5);
  box-sizing: border-box;
  height: 50px;
}
.six-column-cell {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ddd;
  width: calc(100% / 6);
  box-sizing: border-box;
  height: 51px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.seven-column-cell {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ddd;
  width: calc(100% / 7);
  box-sizing: border-box;
  height: 50px;
}
.eight-column-cell {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ddd;
  width: calc(100% / 8);
  box-sizing: border-box;
  height: 50px;

}
.eleven-column-cell {
  display: inline-block;
  padding: 20px 20px 40px 10px;
  border: 1px solid #ddd;
  width: calc(100% / 11);
  box-sizing: border-box;
  height: 80px;
}
.row:nth-child(even) .cell {
  background-color: #f9f9f9;
}

.row:nth-child(odd) .cell {
  background-color: #fff;
}

.table-row, .product-table-row, .product-table-small-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-row {
  height: 50px;
}

.product-table-row {
  height: 80px;
}

.product-table-small-row {
  height: 50px;
}

.action {
  margin-top: -12px;
}



/* End Table */
/* Responsive Table Styles */

/* Medium screens (tablets, 768px and up) */
@media (max-width: 1024px) {
  .table-header, .table-row, .product-table-row, .product-table-small-row {
    font-size: 12px;
    height: auto;
    padding: 10px;
  }
  .inventory-cell, .vendor-cell, .product-quantity-cell, .product-heading-cell,
  .product-heading-quantity-cell, .product-heading-action-cell,
  .product-heading-small-action-cell, .two-column-cell, .three-column-cell,
  .four-column-cell, .five-column-cell, .six-column-cell, .seven-column-cell,
  .eight-column-cell, .eleven-column-cell {
    width: 100%; /* Stack cells vertically */
    height: auto;
    padding: 8px;
    display: block;
    box-sizing: border-box;
  }
}

/* Small screens (mobile, 480px and up) */
@media (max-width: 768px) {
  .table-header, .row-header, .user-row-header, .website-row-header,
  .curriculum-row-header {
    font-size: 12px;
    text-align: left;
    padding: 5px;
  }
  .table-header-content, .header-box {
    font-size: 10px;
    padding: 5px;
  }
  .table-row, .product-table-row, .product-table-small-row {
    flex-direction: column; /* Stack rows vertically */
    align-items: flex-start;
  }
  .inventory-cell, .vendor-cell, .product-quantity-cell, .two-column-cell,
  .three-column-cell, .four-column-cell, .five-column-cell, .six-column-cell,
  .seven-column-cell, .eight-column-cell, .eleven-column-cell {
    font-size: 12px;
    padding: 5px;
    text-align: left;
  }
}

/* Extra small screens (mobile, below 480px) */
@media (max-width: 480px) {
  .table-container, .table-default-container {
    padding: 12px;
  }
  .table-header, .row-header, .user-row-header, .website-row-header,
  .curriculum-row-header {
    font-size: 12px;
    padding: 4px;
    margin: 0;
  }
  .table-row, .product-table-row, .product-table-small-row {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  .inventory-cell, .vendor-cell, .product-quantity-cell, .product-heading-cell,
  .product-heading-quantity-cell, .product-heading-action-cell,
  .product-heading-small-action-cell, .two-column-cell, .three-column-cell,
  .four-column-cell, .five-column-cell, .six-column-cell, .seven-column-cell,
  .eight-column-cell, .eleven-column-cell {
    width: 100%;
    font-size: 12px;
    padding: 4px;
    height: auto;
    display: block;
  }
  .action {
    margin-top: 0;
    text-align: left;
  }
}

/* Ensuring table containers adapt */
.table-container, .table-default-container {
  overflow-x: auto; /* Horizontal scrolling for small screens */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

/* End Responsive Table Styles */

  /* Sidebar  */
  #sidebar {
    grid-area: sidebar;
    height: 100%;
    background-color: #263043;
    overflow-y: auto;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }

  .sidebar-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 0px 30px;
    margin-bottom: 30px;
  }

  .sidebar-title > span {
    display: none;
  }

  .sidebar-brand {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 700;
    color: #d3d3d3;
  }

  .sidebar-list {
    padding: 0;
    list-style-type: none;
  }

  .sidebar-list-item {
    padding: 20px 20px 20px 20px;
    font-size: 18px;
  }

  .sidebar-list-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }

  .sidebar-list-item > a {
    text-decoration: none;
    color: #9e9ea4;
  }

  .sidebar-responsive {
    display: inline !important;
    position: absolute;
    /*
      we want the z-index of the sidebar higher so that
      the charts are not showing over the sidebar
      on small screens
    */
    z-index: 12 !important;
  }

  /* End Sidebar  */

/* Tabs */
.tab-container {
  color: black;
  font-size: 13px;
}

/* Default styling for tabs */
.tab {
  display: flex;
  border-bottom: 1px solid #ccc;
  text-decoration: none;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

/* Buttons inside the tab */
.tab button {
  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: background-color 0.3s;
  flex: 1; /* Equal width buttons */
  text-align: center;
}

/* Hover effect */
.tab button:hover {
  background-color: #ddd;
}

/* Active button style */
.tab button.active {
  background-color: #ddd;
}

/* Checkbox */
.checkbox {
  margin-right: 5px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .tab-container {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .tab button {
    padding: 12px 10px; /* Reduce padding for smaller screens */
    font-size: 12px; /* Smaller button text */
  }
  .sub-header{
    grid-area: header;
    display: flex;
    align-items: center;
    padding: 5px 30px 0 30px;
    box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
    background-color: #efdda8;
    color: #000;
    font-weight: bold;
    cursor: pointer;
    justify-content: center;
    flex-wrap: wrap;
  }
  .sub-header_box{
    grid-area: header;
    display: flex;
    align-items: center;
    padding: 5px 30px 0 30px;
    color: #000;
    font-weight: bold;
    cursor: pointer;
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  .tab-container {
    font-size: 11px; /* Further adjust font size */
  }

  .tab button {
    padding: 10px 8px; /* Reduce padding further for small screens */
    font-size: 11px;
  }
}



  /* Medium <= 992px */

  @media screen and (max-width: 992px) {
    .grid-container {
      grid-template-columns: 1fr;
      grid-template-rows: 0.2fr 3fr;
      grid-template-areas:
        'header'
        'main';
    }

    #sidebar {
      display: none;
    }

    .menu-icon {
      display: inline;
    }

    .sidebar-title > span {
      display: inline;
    }
  }
  /* Small <= 768px */

  @media screen and (max-width: 768px) {

  }

  /* Extra Small <= 576px */

  @media screen and (max-width: 576px) {
    .hedaer-left {
      display: none;
    }
  }

.admin-tab-section{
  padding: 60px 0px 25px 10px;
}

.admin-drop{

}

.inventory-form{
  border: 2px solid;
  border-radius: 25px;
  padding: 20px;
  background: #fff;
}
.form-label{
  display: flex;
  flex-direction: row;
}

.inventory-heading{
  text-align:center;
}

.form-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.inventory-form {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
input[type="date"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button{
  margin-right: 10px;
  width: 80px;
  color: #000;
  height: 40px;
  border: none;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 600;
  background: #fff;
  outline: 1px solid #efdda8 ;
 }

.submit-button:hover{
  background: #efdda8;
  outline: none;
}
.export-button{
  margin-right: 10px;
  width: 120px;
  color: #000;
  height: 40px;
  border: none;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 600;
  background: #fff;
  outline: 1px solid #efdda8 ;
 }

.export-button:hover{
  background: #efdda8;
  outline: none;
}
.product-submit-button{
  margin-right: 10px;
  width: 100px;
  color: #000;
  height: 40px;
  border: 1px solid #efdda8;
  font-size: 13px;
  font-weight: 600;
  background: #fff;
 }

.product-submit-button:hover{
  background: #efdda8;
  outline: none;
}
.submit-button:disabled {
  background-color: #a1a1a1;
  color: #f0f0f0;
  cursor: not-allowed;
  opacity: 0.6;
}
.add-button:disabled {
  background-color: #a1a1a1;
  color: #f0f0f0;
  cursor: not-allowed;
  opacity: 0.6;
}
.add-button{
  margin: 20px;
  width: 80px;
  color: #000;
  height: 40px;
  border: none;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 600;
  background: #fff;
  outline: 1px solid #efdda8 ;
 }

.add-button:hover{
  background: #efdda8;
  outline: none;
}
.view-button{
  margin: 4px;
  width: 100px;
  color: #000;
  height: 40px;
  border: none;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 600;
  background: #fff;
  outline: 1px solid #efdda8 ;
 }

.view-button:hover{
  background: #efdda8;
  outline: none;
}

.close-button{
  width: 80px;
  color: #000;
  height: 40px;
  border: none;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 600;
  background: #fff;
  outline: 1px solid #efdda8 ;
}
.add-curriculum-button{
  width: 150px;
  color: #000;
  height: 40px;
  border: none;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 600;
  background: #fff;
  outline: 1px solid #efdda8 ;
}

.add-curriculum-button:hover{
  background: #efdda8;
  outline: none;
}
.close-button:hover{
  background: #efdda8;
  outline: none;
}

.product-container{
  margin: 40px 80px 20px 110px;
}

.product-image{
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}

.product-slider-image{
  display: flex;
  justify-content: space-evenly;
  height: 100px;
  align-items: center;
  text-align: center;

}

.product-tab-container{
  margin: 40px;
  border: 1px solid #e6e6e6 ;
  font-size: 13px;
}

.p-description{
  font-size: 13px;
}

.product-image{
  border: 1px solid #f2f5f8;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.3);
  border-radius: 10px;
  margin: 0 0px 20px 0;
  transition: .3s all ease-in-out;
  justify-content: center;
  align-items: center;
  width: fit-content;
  display: flex;
  text-align: center;
}

.product-image:hover{
  transform: scale(1.03);
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

.w-24{
  border: 1px solid #f2f5f8;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.3);
  border-radius: 10px;
  margin: 0 0px 20px 0;
  transition: .3s all ease-in-out;
}

.w-24:hover{
  transform: scale(1.03);
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}
.flex-p{
  display: flex;
  justify-content: space-evenly;
}

.w-24{
  width:70px;
  height: 60px;
}

.w-24{
  border: 1px solid #f2f5f8;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.3);
  border-radius: 10px;
  margin: 0 0px 20px 0;
  transition: .3s all ease-in-out;
}
/* Holiday list css */

.holiday-item {
  background-color: #ffffff; /* Default white */
}
.holiday-item.passed-holiday {
  background-color: #f0f0f0; /* Light gray */
}
.holiday-item.first-upcoming-holiday {
  background-color: #d4f5d4; /* Light green */
}

/* Breadcrumb.css */
.breadcrumb {
  padding: 8px 16px;
  background-color: #f5f5f5;
  border-radius: 4px;
  display: inline-block;
  font-family: Arial, sans-serif;
}

.breadcrumb ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.breadcrumb-item {
  margin-right: 8px;
  font-size: 14px;
}

.breadcrumb-item:last-child {
  margin-right: 0;
}

.breadcrumb-item.active span {
  color: #999;
  cursor: default;
}

.breadcrumb-item:not(.active)::after {
  content: '>';
  margin-left: 8px;
  color: #999;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #007bff;
  transition: color 0.2s;
}

.breadcrumb-item a:hover {
  color: #0056b3;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px; /* You can adjust the width as needed */
  display: inline-block;
}

.custom-datepicker {
  width: 100%;
  padding-left: 100px;
}

/* components/Badge.css */

.badge-container {
  position: relative;
  display: inline-block;
}

.badge {
  padding: 8px 30px;
  border-radius: 12px;
  color: white;
  font-weight: bold;
}

.badge.green {
  background-color: #28a745; /* Green for success */
}

.badge.red {
  background-color: #dc3545; /* Red for danger */
}

.badge.orange {
  background-color: #fd7e14; /* Orange for warning */
}

.badge.blue {
  background-color: #007bff; /* Blue for info */
}
.badge.yellow {
  background-color: yellow; /* Yellow for info */
}

.badge-dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  margin-top: 5px;
  width: 150px; /* Set a width for the dropdown */
  transition: all 0.3s ease; /* Smooth transition for dropdown appearance */
}

.dropdown-item {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-item:hover {
  background-color: #f1f1f1; /* Light gray on hover */
}

.dropdown-item:active {
  background-color: #e0e0e0; /* Darker gray on active */
}
.chat-container {
  display: flex;
  flex-direction: column;
  height: 400px;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
}

.chat-message {
  margin-bottom: 10px;
}

.admin-message {
  background-color: #e1ffc7;
  padding: 10px;
  border-radius: 5px;
}

.lead-message {
  background-color: #fff9c4;
  padding: 10px;
  border-radius: 5px;
}

.message-time {
  font-size: 0.8em;
  color: #888;
}

.chat-input {
  display: flex;
  flex-direction: column;
}

.chat-input textarea {
  resize: none;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 100px;
}

.chat-input button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.chat-input button:hover {
  background-color: #0056b3;
}

.container {
  /* For WebKit-based browsers */
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #f5f5f5;
}

/* Chrome, Edge, and Safari */
.container::-webkit-scrollbar {
  width: 6px; /* Adjust the width to make it thinner */
}

.container::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
  background-color: #c0c0c0; /* Color of the scrollbar */
  border-radius: 10px;
  border: 1px solid #f5f5f5;
}
.description-container {
  /* For WebKit-based browsers */
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #f5f5f5;
}

/* Chrome, Edge, and Safari */
.description-container::-webkit-scrollbar {
  width: 6px; /* Adjust the width to make it thinner */
}

.description-container::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 10px;
}

.description-container::-webkit-scrollbar-thumb {
  background-color: #c0c0c0; /* Color of the scrollbar */
  border-radius: 10px;
  border: 1px solid #f5f5f5;
}

@media print {
  /* Force color mode for printing */
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  /* Style specific to the table and modal */
  .description-modal .products-table th,
  .description-modal .products-table td {
    color: inherit; /* Keeps original color */
    background-color: inherit; /* Keeps original background */
  }

  /* Optional: adjust button visibility */
  .submit-button, .print-button {
    display: none; /* Hide buttons during print */
  }
}
.notification-header {
  font-size: 22px;
  font-weight: bold;
  color: red;
  padding: 0.5rem 0;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  margin-left: 0;
}

/* Extra small screens (up to 480px) */
@media (max-width: 480px) {
  .notification-header {
    width: 89%;
    margin-left: 5%;
    font-size: 15px;
  }
}

/* Small screens (481px to 767px) */
@media (min-width: 481px) and (max-width: 767px) {
  .notification-header {
    width: 80%;
    margin-left: 15%;
    font-size: 16px;
  }
}

/* Medium screens (768px to 1023px) */
@media (min-width: 768px) and (max-width: 1023px) {
  .notification-header {
    width: 90%;
    margin-left: 5%;
    font-size: 18px;
  }
}

/* Large screens (1024px to 1279px) */
@media (min-width: 1024px) and (max-width: 1279px) {
  .notification-header {
    width: 100%;
    font-size: 20px;
  }
}

/* Extra large screens (1280px to 1535px) */
@media (min-width: 1280px) and (max-width: 1535px) {
  .notification-header {
    width: 100%;
  }
}

/* Ultra wide screens (1536px and above) */
@media (min-width: 1536px) {
  .notification-header {
    width: 100%;
  }
}

.menu-name{
  margin-left: 8px;
  margin-right: 5px;
  font-size: 14px;
  font-weight: bold;
}

.custom-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #a0aec0 #edf2f7; /* Thumb and track colors */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #a0aec0; /* Thumb color */
  border-radius: 4px; /* Round the thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #718096; /* Darker thumb on hover */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #edf2f7; /* Track color */
}

